export const environment = {
  //Projeto webapp
  production: true,
  urlApigee: 'https://apigw-qa.fastshop.com.br',
  uri: 'sellercenter/v0/iam/fastid/fastshop',
  marketplaceId: 'sellercenter-qa',
  linkFastShopSSO: 'https://sellercenter-develop.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=fastshopadibm&redirect_uri=https://parceirofast-develop.fastshop.com.br/&response_type=CODE&client_id=6pmphug93lijgjubj22oqlkb3&scope=aws.cognito.signin.user.admin%20email%20openid%20profile',
  sellerManagementApiURL : 'https://seller-management-api-develop.devqa.fastshopdigital.com/sellercenter'
};

export const SellerPortalHosts = {
  SELLERCENTER: 'https://apigw-dev.fastshop.com.br',
  CADASTRO: 'https://apigw-dev.fastshop.com.br/mpc/v0/shop-mpc',
  SELLER: 'https://seller-management-develop.fastshop.com.br/home',
  FINANCEIRO : "https://apigw-dev.fastshop.com.br/financial/v0/financial-manager",
  FMS: "https://apigw-dev.fastshop.com.br/financial/v0/financial-shipping"
}

export const SellerPortalHostsApigee = {
  SELLERCENTER: 'https://apidev.fastshop.com.br/v1/sellercenter',
  CADASTRO: 'https://apidev.fastshop.com.br/shop-mpc',
  SELLER: 'https://seller-management.fastshop.com.br/home',
  FINANCEIRO : "https://apidev.fastshop.com.br/v1/financial-manager",
  FMS: "https://apidev.fastshop.com.br/fms-api"
}